class biokunststoffe_infograph {
    constructor( ){

        let mq900_max = window.matchMedia('screen and (max-width: 900px)')

        function scrollto(distance){
            document.scrollingElement.scroll({
                top: distance,
                behavior: 'smooth'
            })
        }

        if( document.querySelector('.biokunststoffe_infograph .marker_basic') ){
            document.querySelector('.biokunststoffe_infograph .marker_basic').addEventListener('click',(event) =>{
                event.preventDefault()
                window.location.href = "/produkte/arbotrade-wuchshulle-basic";
            })
            document.querySelector('.biokunststoffe_infograph .marker_basic').addEventListener('mouseenter',(event) =>{
                document.querySelector('.biokunststoffe_infograph .marking_basic').classList.add('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_x_100').classList.add('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_y_50').classList.add('highlight')
            })
            document.querySelector('.biokunststoffe_infograph .marker_basic').addEventListener('mouseleave',(event) =>{
                document.querySelector('.biokunststoffe_infograph .marking_basic').classList.remove('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_x_100').classList.remove('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_y_50').classList.remove('highlight')

            })
        }

        if( document.querySelector('.biokunststoffe_infograph .marker_premium') ){
            document.querySelector('.biokunststoffe_infograph .marker_premium').addEventListener('click',(event) =>{
                event.preventDefault()
                window.location.href = "/produkte/arbotrade-wuchshulle-premium";
            })
            document.querySelector('.biokunststoffe_infograph .marker_premium').addEventListener('mouseenter',(event) =>{
                document.querySelector('.biokunststoffe_infograph .marking_premium').classList.add('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_x_100').classList.add('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_y_100').classList.add('highlight')
            })
            document.querySelector('.biokunststoffe_infograph .marker_premium').addEventListener('mouseleave',(event) =>{
                document.querySelector('.biokunststoffe_infograph .marking_premium').classList.remove('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_x_100').classList.remove('highlight')
                document.querySelector('.biokunststoffe_infograph .legend_y_100').classList.remove('highlight')
            })
        }

        if( document.querySelector('.biokunststoffe_infograph .legend_y_100_title') ){
            document.querySelector('.biokunststoffe_infograph .legend_y_100_title').addEventListener('click',(event) =>{
                event.preventDefault()
                // console.log("legend_y_100_title")
                let distance
                if (mq900_max.matches) {
                    //
                    distance = document.querySelector(".col_1:nth-of-type(3) .infobox:nth-of-type(2)").getBoundingClientRect().top + window.scrollY - document.querySelector("body > header").offsetHeight + (((window.innerHeight / 100 * 1.5625) + 8) * 2)
                }else{
                    //
                    distance = document.querySelector(".col_1:nth-of-type(3) .infobox:nth-of-type(2)").getBoundingClientRect().top + window.scrollY - document.querySelector("body > header").offsetHeight + (((window.innerHeight / 100 * 1.5625) + 8) * 2)
                }

                scrollto(distance)
            })
        }

        if( document.querySelector('.biokunststoffe_infograph .legend_x_100_title') ){
            document.querySelector('.biokunststoffe_infograph .legend_x_100_title').addEventListener('click',(event) =>{
                event.preventDefault()
                // console.log("legend_x_100_title")
                let distance
                if (mq900_max.matches) {
                    //
                    distance = document.querySelector(".col_1:nth-of-type(3) .infobox:nth-of-type(3)").getBoundingClientRect().top + window.scrollY - document.querySelector("body > header").offsetHeight + (((window.innerHeight / 100 * 1.5625) + 8) * 2)
                }else{
                    //
                    distance = document.querySelector(".col_1:nth-of-type(3) .infobox:nth-of-type(3)").getBoundingClientRect().top + window.scrollY - document.querySelector("body > header").offsetHeight + (((window.innerHeight / 100 * 1.5625) + 8) * 2)
                }
                scrollto(distance)
            })
        }

    }
}
export default biokunststoffe_infograph
